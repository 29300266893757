// @flow

import classNames from 'classnames';
import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '@riseart/antd-provider';
import { Link, ExpandableOverflow, MediaQuery, Paragraph } from '@riseart/common';
import { Section, Wrapper } from '@riseart/layout';
import {
  ArrowPointCircleIcon,
  PersonCircleIcon,
  CheckCircleIcon,
  LockCircleIcon,
} from '@riseart/icons';
import { ContentAttribute } from 'shared_components/cms/attributes/Content';
import { CmsHeading } from 'shared_components/cms/attributes/Heading';

import { raScreenMd } from '@riseart/antd-provider/dist/website/variables.less';
import {
  trustSectionCls,
  trustSectionItemCls,
  trustSectionItemIconCls,
  trustSectionFooterCls,
  trustSectionTextCls,
} from 'shared_components/cms/modules/info/TrustSection.less';

type Props = {
  className?: string,
  disableHeadingTags?: boolean,
  title?: Object,
  subTitle?: Object,
  text?: { type: string, text: string },
  footer: {
    text: string,
    link: {
      external: boolean,
      target: string,
      href: string,
      text?: string,
    },
  },
  items: Array<{
    title: Object,
    description: string,
    icon: string,
    link: {
      external: boolean,
      target: string,
      href: string,
      text?: string,
    },
  }>,
};

/**
 * CmsModuleTrustSection
 *
 * @param {Props} props
 * @returns {Node}
 */
export const CmsModuleTrustSection = ({
  title,
  subTitle,
  text,
  footer,
  items,
  className,
  disableHeadingTags = false,
}: Props): Node => {
  const totalItems = items && items.length;

  if (!items || !totalItems) {
    return null;
  }

  const ICON_COMPONENTS = {
    pointArrow: ArrowPointCircleIcon,
    check: CheckCircleIcon,
    lock: LockCircleIcon,
    person: PersonCircleIcon,
  };

  return (
    <Section className={classNames(trustSectionCls, className)} background="gray">
      <Wrapper
        sizes={{
          xs: 'wide',
          sm: 'narrow',
          md: 'wide',
          lg: 'narrow',
          xl: 'narrow',
        }}
      >
        {title ? (
          <CmsHeading
            {...(title && title.text ? title : null)}
            disableHeadingTags={disableHeadingTags}
            defaultTag="h2"
            defaultLevel="3"
            align="center"
          />
        ) : null}
        {subTitle ? (
          <CmsHeading
            {...(subTitle && subTitle.text ? subTitle : null)}
            disableHeadingTags={disableHeadingTags}
            defaultTag="h3"
            defaultLevel="5"
            align="center"
          />
        ) : null}
        {text ? (
          <div className={trustSectionTextCls}>
            <MediaQuery minWidth={raScreenMd}>
              {(isTablet) =>
                isTablet ? (
                  <ContentAttribute component="p" plainTextWrapper="p" {...text} />
                ) : (
                  <ExpandableOverflow
                    maxHeight={200}
                    gradientColor="gray"
                    showMore={<FormattedMessage id="common.show_more" />}
                    showLess={<FormattedMessage id="common.show_less" />}
                  >
                    <ContentAttribute component="p" plainTextWrapper="p" {...text} />
                  </ExpandableOverflow>
                )
              }
            </MediaQuery>
          </div>
        ) : null}
        <Row
          gutter={[
            { xs: 32, md: 64 },
            { xs: 32, md: 64 },
          ]}
          justify="center"
        >
          {items.map(({ title, description, icon, link }, idx) => {
            const Icon = ICON_COMPONENTS[icon];

            return (
              <Col key={idx} xs={24} sm={12} md={totalItems > 3 ? 6 : 8}>
                <div className={trustSectionItemCls}>
                  {Icon && (
                    <div className={trustSectionItemIconCls}>
                      <Icon />
                    </div>
                  )}
                  <CmsHeading
                    {...(title && title.text ? title : null)}
                    disableHeadingTags={disableHeadingTags}
                    defaultTag="h3"
                    defaultLevel="4"
                  />
                  <Paragraph>{description}</Paragraph>
                  <div>
                    <Link
                      href={link.href}
                      title={link.text}
                      external={link.external}
                      {...(link.target ? { target: link.target } : null)}
                    >
                      {link.text}
                    </Link>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        {footer && (footer.text || footer.link) ? (
          <div className={trustSectionFooterCls}>
            {footer.text ? <span>{footer.text}</span> : null}
            {footer.link ? (
              <React.Fragment>
                {' '}
                <Link
                  href={footer.link.href}
                  title={footer.link.text}
                  external={footer.link.external}
                  {...(footer.link.target ? { target: footer.link.target } : null)}
                >
                  {footer.link.text}
                </Link>
              </React.Fragment>
            ) : null}
          </div>
        ) : null}
      </Wrapper>
    </Section>
  );
};
