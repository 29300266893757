// @flow

import React, { useMemo } from 'react';
import type { Node } from 'react';
import { Heading } from '@riseart/common';

const ALLOWED_TAGS = ['span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const ALLOWED_LEVELS = ['level1', 'level2', 'level3', 'level4', 'level5', 'level6'];

type Props = {
  tag?: string,
  level?: string,
  text?: string,
  defaultTag?: string,
  defaultLevel?: string,
  disableHeadingTags?: boolean,
  children?: Node,
  align?: string,
};

/**
 * CmsHeading
 *
 * @param {Props} props
 * @returns {Node}
 */
export function CmsHeading({
  text,
  tag: tagProp,
  level: levelProp,
  defaultTag,
  defaultLevel,
  disableHeadingTags = false,
  children,
  ...restProps
}: Props): Node {
  // Determine level
  const level = useMemo(() => {
    if (levelProp && ALLOWED_LEVELS.indexOf(levelProp) > -1) {
      return levelProp.replace('level', '');
    }

    return defaultLevel;
  }, [levelProp, defaultLevel]);

  // Determine tag
  const tag = useMemo(() => {
    if (tagProp && ALLOWED_TAGS.indexOf(tagProp) > -1) {
      return tagProp;
    }

    return disableHeadingTags ? 'span' : defaultTag;
  }, [tagProp, defaultTag, disableHeadingTags]);

  // Determine text content
  const content = useMemo(() => text || children, [text, children]);

  return content ? (
    <Heading tag={tag} level={level} {...restProps}>
      {content}
    </Heading>
  ) : null;
}
